.BackOfHouse {
    .bio-container {
        width: 100%;
        height: 100%;

        .header {
            position: relative;
            width: 100%;
            margin-top: 30px;

            .logo {
                position: absolute;
                top: 0;
                right: 20px;
                width: 140px;
            }

            .text {
                margin-left: 8%;

                .title {
                    text-transform: uppercase;
                    text-align: left;
                    margin: 0;
                }
            }
        }

       

        .bio-info-container {
            display: flex;
            flex-direction: row;
            margin-top: 3%;

            .bio-celebrate {
                height: 700px;
            }

            .bio-picture-container {
                padding: 0 150px 0 150px;
                overflow: hidden;
                height: 640px;
                width: 600px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: inherit;
                    height: inherit;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }

            .bio-info {
                margin-left: 40px;
                
                .bio-info-title {
                    font-weight: bold;
                    font-size: 60px;
                }

                .bio-info-inputs {
                    font-size: 40px;
                    label {
                        font-weight: bold;
                    }

                }

            }
        }
        

    }
}
