@import "colors";
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic');
@import '~react-table-legacy/react-table.css';

:root {
    --bs-link-color-rgb: $DarkSlateBlue;
    --bs-link-color: $DarkSlateBlue;
    --bs-body-font-family: "Roboto";
}

html, body {
    height: 100%;
}

body {
    overflow: hidden;
}

#apollo_root {
    height: 100%;
}

svg.fa-instagram.colorful * {
    fill: url(#instagram);
}

svg.fa-twitter.colorful * {
    color: #1DA1F2;
}

.refreshTokenIframe {
    border: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 10000;
    background: white;
}

.manito {
    &:hover {
        cursor: pointer;
    }
}

.outline-none {
    outline: none !important;
}

.btn-xs {
    padding: .15rem .325rem;
    font-size: 0.7em;
    line-height: 0.7em;
}

@media only screen and (max-width: 574px) {
    .app-wrapper {
        padding-left: 0;
    }
}

.text-button,
.pointer-button {
    background-color: transparent;
    border: none;
    display: inline;
    margin: 0;
    padding: 0;
}

.text-button,
.text-button:hover,
.text-button:focus {
    cursor: text;
}

.pointer-button,
.pointer-button:hover,
.pointer-button:focus {
    cursor: pointer;
}

.tooltip {
    pointer-events: none;
}

.loading {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .loading-inner {
        position: relative;
        background: white;
        border-radius: 50%;
        border: 3px solid white;
    }
}

.super-small-loading, .small-loading {
    position: relative;
    height: 50px;
    box-sizing: border-box;
    margin: 0;

    .wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .loading-inner {
            position: relative;
            background: white;
            border-radius: 50%;
            border: 3px solid white;
        }
    }
}

.super-small-loading {
    height: 24px;
    line-height: 24px;

    .wrapper {
        .loading-inner {
            border: none;
            padding: 0;
            margin: 0;
        }
    }
}

.width_less_popover {
    max-width: initial !important;
}

.badge {
    &.sm {
        font-size: 50%;
    }

    &.xs {
        font-size: 25%;
    }
}

.table {
    thead {
        th {
            background-color: $TableHeadGrey;
        }
    }

    td {
        vertical-align: middle;
    }

    .table-icon {
        max-height: 35px;
        border-radius: 8px;
        border: 1px solid rgb(222, 222, 222);
    }
}

.single-line-ellipsis {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.UPModal {
    .modal-header {
        .modal-title {
            width: 100%;
            display: flex;
            align-items: center;

            .modal-actions {
                margin-left: auto;
            }
        }

    }
}

@media only screen and (max-width: 992px) {
    .UPModal.modal-dialog.modal-xl, .UPModal.modal-dialog.modal-lg {
        max-width: 100vw;
    }
}

.StrippedBackground {
    background-size: 50px 50px; /* Controls the size of the stripes */
    background-color: $Grey;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%,
            transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%,
            transparent 75%, transparent);
}

.react-bootstrap-table {
    .table {
        table-layout: auto;
    }
}

.content-piece {
    width: 150px;
    height: 180px;

    a {
        height: 100%;
    }

    .asset {
        width: 150px;
        height: 150px;
    }
}

.btn-primary {
    @include button-variant($DarkSlateBlue, $DarkSlateBlue, $White, darken($DarkSlateBlue, 4.5%), $DarkSlateBlue, $White);
}
.btn-secondary {
    @include button-variant($BrownishGreyTwo, $BrownishGreyTwo, $White, darken($BrownishGreyTwo, 6.5%), $BrownishGreyTwo, $White);
}
.btn-danger {
    @include button-variant($Redish, $Redish, $White, darken($Redish, 7.5%), $Redish, $White); 
}
.btn-success {
    @include button-variant($Greenish, $Greenish, $White, darken($Greenish, 4.5%), $Greenish, $White);
}
