.BackOfHouse {
    &.boh_type_customer_comments {
        padding: 5vh 10vw;
        padding-bottom: calc(5vh + 100px)
    }

    .BOHComments {
        .title {
            margin: 32px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: var(--title-font-size-large);
            text-transform: uppercase;
            min-height: 1em;
            line-height: 1;

            .text {
                width: 90%;
                text-align: left;
            }

            .logo {
                height: 1.5em;
            }
        }

        .header {
            position: relative;
            margin-bottom: 32px;

            .dash {
                width: 42px;
                border-bottom: solid black 2px;
            }

            .empty-comment {
                font-size: var(--body-font-size-large);
                padding-top: 12px;
            }

            .satisfaction {
                align-self: center;
                height: 1em;
                margin: 32px 0;
                padding: 16px;
                line-height: 1;
                border-radius: 8px;
                color: white;
                font-size: var(--body-font-size-small);
                text-transform: uppercase;
                font-weight: bold;
            }

            .date {
                font-size: var(--body-font-size-small);
            }

            .quote {
                &::before {
                    content: "’’";
                    letter-spacing: 1px;
                }

                opacity: 0.16;
                position: absolute;
                font-size: calc(var(--title-font-size-large) * 4);
                line-height: 1;
                height: 100%;
                right: 0;
                top: -24px;
                font-family: "Cabin", sans-serif;
                letter-spacing: -22.5px;
                font-weight: bolder;
            }
        }

        .content {
            font-size: var(--body-font-size-medium);
            margin-bottom: 16px;
            color: #656979;
        }

        .rating svg {
            color: #faaf18;
            font-size: var(--body-font-size-large);
        }

        .flex {
            display: flex;
        }
    }
}
