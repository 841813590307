.BackOfHouse {
    .goal-progress-container {
        width: 534px;
        height: 534px;

        .description-progress {
            font-size: 38px;
        }

        .percentage-progress {
            font-size: 150px;
            display: flex;
            align-items: baseline;
            font-weight: bold;

            .percentage-symbol {
                font-size: 70px;
            }
        }

        .goal-description {
            font-size: 33px;
            width: 333px;
            height: 50px;
            text-align: center;
        }
    }
}