.BackOfHouse {
    .store-speed-of-service {
        width: 1168px;
        height: 100%;

        box-sizing: border-box;
        padding-right: 1%;
        .width-container {
            width: 80%;
            .header {

                .title {
                    font-size: 62px;
                    text-align: left;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                    min-height: 1em;
                    line-height: 1;
                }

                .description {
                    font-size: 32px;
                    text-align: left;
                    margin: 0;
                    color: inherit;
                }
            }

            .sos-table {
                display: flex;
                flex-direction: column;

                margin-top: 36px;

                font-size: 32px;

                .list-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    font-weight: 400;
                    height: 28px;

                    &.list-header {
                        padding: 21px;
                        color: #fafafa;
                        display: flex;
                        align-items: center;

                        p {
                            font-size: 32px;
                        }
                    }

                    &.list-item-own {
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            opacity: 0.3;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            background-color: var(--accent-color);
                        }
                    }

                    &.list-item {
                        height: 65px;
                        padding: 14px 21px;
                        border-bottom: 1px solid #6E6E6E;
                        font-weight: bold;
                    }
                }
            }
        }


        .results-description {
            font-size: 24px;
            text-align: right;
            margin: 0;
            color: inherit;
            font-style: italic;
            margin-top: 14px;
            opacity: 0.7;
        }
    }
}
