@import '_colors.scss';

#menu {
    grid-area: menu;
    display: flex;

    transition: width 90ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    box-shadow: 2px 0 2px -1px rgba(152, 162, 179, 0.3);
    overflow: hidden;
    z-index: 1035;

    .Menu {
        padding: 8px;
        max-width: 400px;
        overflow-y: auto;

        margin-bottom: 48px;

        li {
            font-size: 14px;
            line-height: 16px;

            padding: 0;
            margin-top: 8px;
            border-radius: 4px;
            overflow: hidden;
            display: flex;
            align-items: center;
            position: relative;

            a {
                padding: 8px;
                display: flex;
                align-items: center;
                flex: 1 0 auto;
                text-align: left;
                max-width: 100%;
                height: 32px;
                text-decoration: none;
                border: 1px solid transparent;

                .label {
                    margin-left: 10px;
                    color: $BrownishGrey;
                }
                svg {
                    color: $BrownishGrey;
                    fill: $BrownishGrey;
                }

                &:hover, &:focus, &.selected {
                    background-color: $WhiteTwo;
                    border: 1px solid $Blue;
                    border-radius: 4px;
                    .label {
                        color: $Blue;
                    }
                    svg {
                        color: $Blue;
                        fill: $Blue;
                    }
                }
            }
        }

        &.collapsed {
            .label {
                display: none;
            }
        }
    }

    .flexGrowZero {
        flex-grow: 0;
        flex-basis: auto;
        display: flex;
        flex-direction: column;
        width: 240px;
    }

    .open_menu_option {
        font-size: 14px;
        line-height: 16px;

        box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2), 0 0 2px 0 rgba(152, 162, 179, 0.2);
        background-color: #FFF;
        position: fixed;
        bottom: 0;
        width: 48px;
        height: 40px;

        transition: width 90ms;

        z-index: 1001;

        padding: 12px 16px;

        &:hover, &:focus {
            background-color: #f5f7fa;
            color: $DarkSlateBlue;
            svg, .label{
                color: $DarkSlateBlue;
            }
        }

        .fa-layers {
            margin-right: 12px;
        }
        .label {
            color: $BrownishGrey;
        }
    }

    &.expanded {
        .open_menu_option {
            width: 240px;
            color: $DarkSlateBlue;
            svg, .label{
                color: $DarkSlateBlue;
            }
        }
    }

    @media only screen and (max-width: 574px) {
        .Menu {
            margin-bottom: 0;
        }
        .open_menu_option {
            display: none;
        }
    }
}

.userMenu{
    .popover {
        max-width: 574px;
    }
    .user_avatar {
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
}
