.BackOfHouse {
    .product-highlight-container {
        width: 100%;
        height: 100%;

        .header {
            position: relative;
            width: 100%;
            margin-top: 30px;

            .logo {
                position: absolute;
                top: 0;
                right: 20px;
                width: 140px;
            }

            .text {
                margin-left: 50px;
                .title {
                    text-transform: uppercase;
                    text-align: left;
                    margin: 0;
                    font-size: 110px !important;
                }
            }
        }

        .product-highlight-info-container {
            display: flex;
            flex-direction: row;
            margin-top: 3%;
            margin-left: 50px;

            .product-highlight-img {
                margin-top: 40px;
            }

            .product-highlight-picture-container {
                padding: 0 150px 0 150px;
                overflow: hidden;
                height: 640px;
                width: 600px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: inherit;
                    height: inherit;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }

            .product-highlight-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;

                .product-highlight-info-title {
                    font-weight: 700;
                    font-size: 90px;
                    width: 1000px;
                    overflow-wrap: break-word;
                }
                .product-highlight-info-legend {
                    font-size: 70px;
                    font-weight: 400;
                }
            }
        }
        

    }
}
