.BackOfHouse {
    .loyalty-gauge-goal-progress-container {
        width: 1000px;
        margin-top: 85px;

        .description-progress {
            margin-top: -230px;
            font-size: 58px;
        }

        .percentage-progress {
            font-size: 250px;
            display: flex;
            align-items: baseline;
            font-weight: bold;

            .percentage-symbol {
                font-size: 120px;
            }
        }

        .goal-description {
            font-size: 33px;
            width: 333px;
            height: 50px;
            text-align: center;
        }
    }
}