.BackOfHouse {
    .thermometer-sales-goal-container {
        width: 100%;
        height: 100%;

        .header {
            position: relative;
            width: 100%;
            margin-top: 30px;

            .logo {
                position: absolute;
                top: 0;
                right: 20px;
                width: 140px;
            }

            .text {
                margin-left: 8%;

                .title {
                    text-transform: uppercase;
                    text-align: left;
                    margin: 0;
                }

                .updated-time {
                    margin: 0;
                    font-size: 35px;
                }
            }
        }

        .thermometer-sales-goal {
            position: relative;
            width: 100%;
            height: 100%;

            .thermometers-container {
                position: relative;
                margin-top: 24px;
                width: 100%;
                height: 70%;
                display: flex;
                justify-content: center;
                align-items: center;

                .stretch-thermometer {
                    opacity: 0;
                }
            }

            .total-sales-container {
                position: absolute;
                bottom: 20%;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
                align-items: center;

                .total-sales-text {
                    font-size: 40px;
                }

                .total-sales-amount {
                    font-size: 60px;
                }
            }
        }
    }
}
