.Congratulations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    position: absolute;

    .Lottie {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
    }

    .congratulations-title {
        position: absolute;

        top: 50%;
        transform: translate(0, -100%);

        font-size: 104px;
        text-align: center;
        margin-top: 140px;

        span {
            font-size: 64px;
        }


        &.animated {
            @keyframes make-bigger {
                0% {
                    transform: translate(0, -100%) scale(1);
                    opacity: 1;
                }
                100% {
                    transform: translate(0, -120%) scale(2);
                    opacity: 0.2;
                }
            }


            animation: make-bigger 1s linear;


            span {
                color: transparent;
            }
        }
    }
}
