.BackOfHouse {
    .celebration-container {
        width: 100%;
        height: 100%;
        
        .header {
            font-size: 170px;
            position: relative;
            width: 100%;
            margin-top: 30px;

            .logo {
                position: absolute;
                top: 0;
                right: 20px;
                width: 140px;
            }

            .text {
                margin-left: 50px;

                .title {
                    text-transform: uppercase;
                    text-align: left;
                    margin: 0;
                    font-size: 110px !important;
                }
            }
        }

        .celebration-info-container {
            position: absolute;
            top: 50%;
            margin-left: 50px;

            .celebration-info {
                display: flex;
                width: 100%;

                .celebration-info-title {
                    font-weight: bold;
                    font-size: 90px;
                }
            }
        }
        

    }
    .celebration-background{
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
}
