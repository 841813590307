$WhiteTwo: #f8f8f8;
$DarkSkyBlue: #3aace2; 
$DarkSlateBlue: #21445e; 
$BrownishGrey: #676767; 
$BrownishGrey50: #67676780; 
$BrownishGreyTwo: #707070; 
$Greyish: #d0d0d0; 
$Redish: #dc3b3b;
$Greenish: #00b894; 
$Yellowish: #ffc107;

$Black: #000000;
$White: #FFFFFF;
$Navy: #060D33;
$Indigo: #192CF8;
$Magenta: #DB39FF;
$Blue: #18B0F4;
$Aquamarine: #04EE9C;
$Grey: #CCCCCC;
$Grey2: #747474;
$TableHeadGrey: rgb(244, 244, 244);
$component-active-bg: $DarkSlateBlue;

$theme-colors: (
        "primary": $DarkSlateBlue,
        "secondary": $BrownishGreyTwo,
        "danger": $Redish,
        "warning": $Yellowish,
        "success": $Greenish,
        "info": $DarkSkyBlue,
        "light": $WhiteTwo,
        "dark": $DarkSlateBlue,
        "upshow": $Magenta,
) !default;

//From manager
:root {
  --text-color-over-light: rgba(0, 0, 0, .75);
  --text-color-over-light-secondary: rgba(0, 0, 0, .5);
  --text-color-over-dark-primary: rgba(255, 255, 255, .85);
  --text-color-over-dark-primary-65: rgba(255,255,255,0.65);
  --text-color-over-dark-primary-4: rgba(255,255,255,0.4);
  --text-color-over-dark-secondary: rgba(255, 255, 255, .6);
  --background-color-dark: #cdcdcd;
  --background-color-darker: #313131;
  --background-color-light: #f6f6f6;
  --background-color-lighter: #ffffff;
  --anchor-text-color: #3aace2;
  --light-cyan: #6cd0ff;
  --primary: #00A7F5;
  --primary-20: rgba(0, 167, 245, 0.20);
  --primary-05: rgba(0, 167, 245, 0.05);


  --secondary: #ff0000;
  --accent: #21435F;
  --border-light: #e2e2e2;
  --border-dark: #d4d0d0;
  --green-dollar: #4c9513;
  --bright-sea-green: #58EDB9;
  --purple: #893AE2;

  //redesign
  --red-messages: #DE425B;
  --red-messages-60: rgb(222, 66, 91, .6);
  --dark-sky-blue: var(--anchor-text-color);
  --white: #ffffff;
  --white-two: #f8f8f8;
  --brownish-grey: #707070;
  --brownish-grey-two: #676767;
  --brownish-grey-25: rgb(103, 103, 103, 0.25);
  --brownish-grey-50: rgb(103, 103, 103, 0.5);
  --dark-slate-blue: rgba(33, 68, 94, 1); //#21445e
  --dark-slate-blue-05: rgba(33, 68, 94, 0.05);
  --dark-slate-blue-10: rgba(33, 68, 94, 0.1);
  --dark-slate-blue-20: rgba(33, 68, 94, 0.2);
  --dark-slate-blue-40: rgba(33, 68, 94, 0.4);
  --dark-slate-blue-50: rgba(33, 68, 94, 0.5);
  --dark-slate-blue-60: rgba(33, 68, 94, 0.6);
  --dark-slate-blue-70: rgba(33, 68, 94, 0.7);
  --dark-sky-blue-10: rgba(58, 172, 226, 0.1);
  --dark-sky-blue-80: rgba(58, 172, 226, 0.8);
  --dark-sky-blue-65: rgba(58, 172, 226, 0.65);
  --very-light-gray: #e9e6e6;
  --light-sky-blue: #ebf6fc;
  --black-20: rgba(0, 0, 0, 0.2);
  --light-gray: #f6f6f6;
  --blue-shadow: rgba(33, 68, 94, 0.1);
}
