.BackOfHouse {
    .customer-satisfaction-rates-container {
        display: flex;

        flex-direction: column;

        width: 1920px;
        height: 100%;

        box-sizing: border-box;
        padding-right: 1%;

        .header {
            position: relative;
            width: 80%;
            margin-left: 8%;
            margin-top: 30px;

            .logo {
                position: absolute;
                right: 0;
                top: -12px;
                width: 140px;
            }

            .title {
                text-transform: uppercase;
                text-align: left;
                margin: 0;
            }

            .updated-time {
                margin: 0;
                margin-left: 10px;
                font-size: 28px;
            }
        }

        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 120px;
            width: 100%;
        }


        .goals-container {
            width: calc(100% - 8vw);
            max-height: 60%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            justify-content: space-around;
            padding: 0 8%;
            box-sizing: border-box;
            margin-top: 120px;

            .goal {
                display: flex;
                flex-direction: column;
                align-items: center;

                .goal-percentage {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .goal-value {
                        position: absolute;
                        margin: auto;
                        font-size: 72px;
                        font-weight: bold;

                        span {
                            font-size: 42px;
                            font-weight: bold;
                        }
                    }
                }

                .title {
                    font-size: 48px;
                    color: #393938;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }
    }
}
