.BackOfHouse {
    .new-product-sales {
        width: calc(1920px - 900px);

        .header-section {
            .header {
                .title {
                    font-size: 62px;
                    text-align: left;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                    min-height: 1em;
                    line-height: 1;
                }

                .description {
                    font-size: 32px;
                    text-align: left;
                    margin: 0;
                    color: inherit;
                }
            }
        }

        .table-section {
            margin-top: 42px;
            min-height: 500px;
            width: 100%;
            overflow: hidden;
        }

        .page-indicator-section {
            display: flex;
            justify-content: center;
        }
    }
}
