.BackOfHouse {
    .header-blazin {
        display: flex;
        padding-left: 50px;
        height: 185px;
        justify-content: space-around;
        flex-wrap: nowrap;
        flex-direction: row;

        .header-bar {
            width: 80%;
        }
        .logo {
            width: 10%;
            .logo-icon {
                height: 7em;
                margin-top: 19%;
            }
        }

        .header {
            width: 85%;

            .title {
                font-size: 62px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 8px;
                min-height: 1em;
                line-height: 1;
            }

            .description {
                font-size: 24px;
                text-align: left;
                margin: 0;
                color: inherit;
            }
        }
    }

    .blazin-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-around;
        height: 100%;
        width: 100%;
        box-sizing: border-box;

        .weekly-comparison {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 43%;
            margin-top: 50px;
            background-color: white;
            box-shadow:1px 1px 10px lightgrey;
            border-radius: 15px;
            font-size: 45px;

            .title{
                color: black;
                display: flex;
                align-items: center;
                font-weight: 800;
                font-size: 50px;
                margin-top:5px;
                padding-top: 30px;
            }

            .chart-legend{
                display: flex;
                justify-content: center;
                font-size: 38px;

                .last-week-legend{
                    margin-top: 20px;
                    color: gray;
                    width: 286px;
                }

                .this-week-legend{
                    margin-top: 20px;
                    color: gray;
                }

            }

            .bar-chart{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                border-bottom: solid 1px gray;
                margin-top: 20px;
                width: 80%;
                min-height: 290px;

                .this-week{
                    color: white;
                    width: 155px;
                    min-height:40px;
                    margin-left: 50px;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    display:inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 40px;
                    font-weight: bold;
                    border-radius: 5px 5px 0 0;
                }

                .last-week{
                    color: white;
                    width: 155px;
                    min-height:40px;
                    margin-right: 50px;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    display:inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 40px;
                    font-weight: bold;
                    border-radius: 5px 5px 0 0;
                }

                .red{
                    background-color: #C2344A;
                }

                .green{
                    background-color: #00AE4C;
                }
            }

        }

        .attachment-list {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 43%;
            margin-top: 50px;
            background-color: white;
            box-shadow:1px 1px 10px lightgrey;
            border-radius: 15px;
            padding-right: 20px;
            padding-left: 20px;

            .page-indicator {
                display: flex;
                height: 70px;
                align-self: center;
            }

            .list-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-weight: 400;
                height: 28px;

                &.list-header {
                    padding: 0 21px 21px 21px;
                    color: black;
                    display: flex;
                    align-items: center;
                    font-weight: 800;
                    margin-top: 50px;

                    .employee_header {
                        width: 317px;
                    }

                    p {
                        font-size: 50px;
                    }
                }

                &.list-item {
                    height: 65px;
                    padding: 8px 28px;
                    font-weight: 400;
                    color: gray;

                    .name {
                        font-size: 32px;
                        width: 435px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-transform: capitalize;
                    }

                    .percentage {
                        font-size: 32px;
                        white-space: nowrap;
                        overflow: hidden;

                    }
                }
            }
        }
    }
}