.BackOfHouse {
    .training-status-container {
        width: 1168px;
        height: 100%;

        box-sizing: border-box;
        padding-right: 1%;

        .header {
            width: 80%;

            .title {
                font-size: 62px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 8px;
                min-height: 1em;
                line-height: 1;
            }

            .description {
                font-size: 32px;
                text-align: left;
                margin: 0;
                color: inherit;
            }
        }

        .training-status-list {
            display: flex;
            flex-direction: column;

            width: 80%;
            margin-top: 36px;

            .list-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                font-weight: 400;
                height: 16px;

                .employees {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0;
                    font-weight: 600;
                }

                &.list-header {
                    padding: 21px;
                    color: #fafafa;
                    display: flex;
                    align-items: center;

                    .employee_header {
                        width: 317px;
                    }

                    p {
                        font-size: 32px;
                    }
                }


                &.list-item {
                    height: 36px;
                    padding: 14px 21px;
                    border-bottom: 1px solid #6E6E6E;
                    font-weight: 600;

                    .name {
                        font-size: 28px;
                        width: 435px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-transform: capitalize;
                    }

                    .completed-field {
                        font-size: 32px;
                        font-weight: bold;

                        &--yes {
                            color: #00AE4C
                        }

                        &--no {
                            color: #C2344A
                        }
                    }
                }
            }
        }
    }
}
