$dark-color: #37474f;
$light-color: #eeeeee;

.SpotlightPlayer {
    width: 1920px;
    height: 1080px;
    border-radius: 6px;
    overflow: hidden;
    transform-origin: top left;
    pointer-events: none;
    font-family: "Cabin", sans-serif;

    &.vertical {
        height: 1920px;
        width: 1080px;
    }

    &.rotated {
        .Background {
            height: calc(100% * (9/16)) !important;
            width: calc(100% * (16/9)) !important;
            top: calc(50% - (calc(100% * (9/16)) / 2));
            left: calc(50% - (calc(100% * (16/9)) / 2));
            transform: rotate(-90deg);
        }
    }

    .Background {
        left: 0;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &.onpointer {
        pointer-events: unset;

        .Background {
            pointer-events: unset;
        }
    }

    .TextOverlay {
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;

        color: #fff;
        pointer-events: none;

        .line {
            overflow: hidden;
            padding-left: 8%;
            padding-right: 8%;

            &.empty-text {
                opacity: 0.65;
            }

            &.auto-color {
                mix-blend-mode: difference;
            }
        }

        .header {
            position: absolute;
            bottom: 75%;
            width: 85%;

            &.xlarge {
                top: 9%;
                bottom: 0;
                line-height: 120%;
            }
        }

        .text {
            top: 45%;
            position: absolute;
            width: 85%;

            white-space: pre-wrap;
            overflow-wrap: break-word;

            &.xlarge {
                top: 50%;
            }
        }

        // Positions
        &.top {
            justify-content: flex-start;
        }

        &.middle {
            justify-content: center;
        }

        &.bottom {
            justify-content: flex-end;
        }

        &.left {
            align-items: flex-start;
            text-align: left;
        }

        &.center {
            align-items: center;
            text-align: center;
        }

        &.right {
            align-items: flex-end;
            text-align: right;
        }
    }

    .BackOfHouse {
        position: absolute;
        top: 0;
        left: 0;
        padding: 5vh 5vw;
        box-sizing: border-box;

        height: 100%;
        width: 100%;
    }

    &.has-side-upcode { // When we have a fixed side upcode the width changes to accommodate for this component
        .TextOverlay,
        .Background {
            width: 1220px;
        }
    }

    .QR {
        position: absolute;
        left: 1220px;
        top: 0;
        width: 700px;
        height: 100%;

        display: flex;
        flex-direction: column;

        &.without-logo {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .upcode-logo-container {
                padding: 18px;
                margin: 64px auto;

                width: fit-content;
                border-radius: 16px;

                .qr {
                    &.autogenerated {
                        svg {
                            padding: 24px;
                        }
                    }
                }
            }
        }

        .QR_link {
            display: contents;
            pointer-events: all;
        }

        &.dark {
            border-color: $dark-color;

            .info {
                color: #fafafa;
            }
        }

        &.light {
            border-color: $light-color;

            .info {
                color: $dark-color;
            }
        }

        .upcode-logo-container {
            position: relative;
            border-radius: 26px;
            margin: 94px 94px 40px 94px;
            padding-bottom: 24px;

            -webkit-box-shadow: 0px 5px 19px -9px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 5px 19px -9px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 5px 19px -9px rgba(0, 0, 0, 0.75);

            .logo {
                display: flex;
                justify-content: center;
                margin-top: 26px;
                margin-bottom: 12px;

                img {
                    width: 505px;
                    height: 185px;
                    object-fit: contain;
                }
            }

            .qr {
                // height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                &.autogenerated {
                    padding: 0;
                }

                canvas,
                img,
                svg {
                    object-fit: contain;
                    width: 384px;
                    height: 384px;

                    &.autogenerated {
                        border-radius: 15px;
                        padding: 32px 24px 48px 24px;
                    }
                }
            }

            .qr_click {
                height: 150px;
                width: 150px;
                background-color: #37474f;
                border-radius: 50%;
                display: inline-block;
                position: absolute;

                img {
                    height: 60px;
                    width: 60px;
                    margin: 45px 45px;
                }
            }
        }

        .info {
            height: 250px;
            width: auto;
            padding: 0 75px;
            font-weight: 600;
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 50% 35% 15%;
            font-size: 26px;
            line-height: 34px;

            .info-text {
                padding: 12px;
                width: 220px;
            }

            .info-visit {
                grid-column: 1 / span 2;
                display: flex;
                justify-content: center;
                align-items: center;

                .tracking-url {
                    margin-left: 16px;
                    border-bottom: 1px solid;
                    text-decoration: none;
                    color: inherit;
                }
            }

            .info-img {
                width: 222px;
                height: 120px;
            }
        }
    }

    .Foreground {
        display: flex;

        .logo {
            justify-content: center;
            top: 96px;
            right: 96px;
            position: absolute;
            width: 140px;
            object-fit: contain;
        }
    }

    .BottomBar {
        background-color: var(--accent-color, black);
        height: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 20px calc(4vw + 40px);
        box-sizing: border-box;

        &.is-fat {
            height: 200px;
            padding: 40px calc(4vw + 40px);
        }

        &.is-top {
            bottom: auto;
            top: 0;

            .upshow-logo {
                margin-top: 10px;
                margin-bottom: 0;
            }
        }

        .logo {
            height: 100%;
            margin-right: 50px;
        }

        .title {
            font-size: var(--title-font-size);
            font-family: var(--title-font-family);
            color: var(--accent-text-color, white);
        }


        .upshow-logo {
            color: var(--accent-text-color, white);
            margin-bottom: 10px;
            margin-left: auto;
            flex: 0 0 auto;
        }
    }

    .SideBar {
        background-color: var(--accent-color, black);
        position: absolute;
        width: 700px;

        right: 0;
        top: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 5%;

        .text {
            font-size: 78px;
            text-align: center;
            word-break: break-word;
            color: var(--accent-text-color, white);
        }

        .logo {
            width: 226px;
            height: 226px;
            object-fit: contain;
        }


        .upshow-logo {
            color: var(--accent-text-color, white);
            margin-top: auto;
        }
    }
}
