.PageIndicator {
    display: flex;
    justify-content: center;
    margin: 20px;

    .indicator {
        width: 21px;
        height: 21px;
        margin: 5px;
        border-radius: 50%;

        background-color: #DDDDDD;

        &.current {
            background-color: var(--accent-color);
        }
    }
}