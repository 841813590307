.BackOfHouse {
    .notes-container {
        width: 100%;
        height: 100%;

        .header {
            font-size: 170px;
            position: relative;
            width: 100%;
            margin-top: 30px;

            .logo {
                position: absolute;
                top: 0;
                right: 20px;
                width: 140px;
            }

            .text {
                margin-left: 50px;

                .title {
                    text-transform: uppercase;
                    text-align: left;
                    margin: 0;
                    font-size: 130px !important;
                }
            }
        }

        .notes-body-container {
            position: absolute;
            margin-left: 50px;
            top: 260px;
            width: 87%;
            height: 635px;
            overflow-wrap: break-word;

            display: flex;
            flex-direction: column;

            .notes-date {
                font-size: 52px;
                font-weight: bold;
                margin-bottom: 70px;
            }

            .notes-text {
                font-size: 58px;
                display: -webkit-box;
                -webkit-line-clamp: 9;
                -webkit-box-orient: vertical;
                overflow: hidden;
                white-space: pre-wrap;
                width: 1150px;

                .tex-fit {
                    max-height: 500px;
                }
            }
        }

        .notes-bottom-container {
            position: absolute;
            bottom: 125px;
            width: 92%;

            display: flex;
            justify-content: center;
        }
    }
}
