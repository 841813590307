#grid {
  height: 100%;
  width: 100vw;

  display: grid;
  grid-template-rows: 49px calc(100% - 50px);
  grid-template-columns: 48px calc(100% - 48px) 0;
  grid-template-areas:
          " header header ."
          " menu main main";

  &.expanded {
    grid-template-columns: 240px calc(100% - 240px) 240px;
  }
}
@media only screen and (max-width: 574px) {
  #grid {
    grid-template-columns: 0 100% 0;
    &.expanded {
      grid-template-columns: 240px calc(100% - 240px) 240px ;
    }
  }
}

#main {
  grid-area: main;
  overflow: scroll;
  position: static;

  > .container-fluid:not(.GraphQLSection) {
    margin-bottom: 30px;
  }
}

//  Fix for okta css getting in the way
#main, .modal {
    .custom-checkbox label {
        background-image: none !important;
    }
}

.fa-beat {
  animation:fa-beat 5s ease infinite;
}
@keyframes fa-beat {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.25);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.25);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.25);
  }
  70% {
    transform:scale(1);
  }
  95% {
    transform:scale(1.25);
  }
}