.BackOfHouse {
    &.boh_type_huddle_board {
        box-sizing: border-box;
        padding-top: calc(200px + 48px);
        padding-bottom: 48px;

        .huddleboard-container.multiple {
            height: 100%;
            display: flex;
            justify-content: center;
            gap: 32px;

            .huddleboard-item-container {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                max-width: 716px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                align-items: center;
                gap: 16px;

                .huddleboard-item-title {
                    width: 100%;
                    max-height: 100px;
                    overflow-x: clip;
                    overflow-y: visible;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: center;
                    font-size: 48px;
                }
            }
        }

        .huddleboard-item {
            --speech-bubble-bg-color: #D9D9D9;

            &.status-bad {
                --status-color: #C2344A;
            }

            &.status-invalid {
                --status-color: #afafaf;
            }

            &.status-good {
                --status-color: #52A831;
            }
        }

        .huddleboard-item.multiple {
            overflow: hidden;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 36px;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: center;
            align-items: center;

            border-radius: 32px;
            background-color: white;
            text-align: center;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15), 0 -1px 6px rgba(0, 0, 0, 0.15);

            .huddleboard-header {
                overflow: hidden;
                width: 100%;
                height: 62px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                align-items: center;

                &__date,
                &__custom-text {
                    letter-spacing: 0.15em;
                    font-size: 24px;
                    text-transform: uppercase;
                }

                &__custom-text {
                    margin-top: 8px;
                }
            }

            .huddleboard-body {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-content: center;
                align-items: center;
                flex-grow: 1;
                overflow: hidden;

                .huddleboard-feedback {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: flex-start;
                    flex-wrap: wrap;

                    box-sizing: border-box;
                    position: relative;
                    width: calc(100% - 140px);
                    min-height: 60px;
                    padding: 8px 16px;

                    background-color: var(--speech-bubble-bg-color);
                    border-radius: 12px;
                    font-size: 22px;
                    column-gap: 4px;
                    row-gap: 2px;

                    &__word {
                        text-transform: uppercase;
                        color: var(--status-color);
                    }

                    &__text {
                        color: black;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        border-style: solid;
                        border-width: 30px 0 0 18px;
                        border-color: transparent transparent transparent var(--speech-bubble-bg-color);
                        top: 0;
                        right: -16px;
                        margin-top: 12px;
                    }

                    .huddleboard-status__emoji {
                        position: absolute;
                        width: 115px;
                        top: 12px;
                        right: -138px;
                    }
                }

                .huddleboard-kpi {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 8px;

                    .huddleboard-value {
                        font-size: 140px;
                        font-weight: 700;
                        line-height: 145px;
                    }

                    .huddleboard-goal {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;

                        &__value {
                            font-size: 42px;
                        }

                        &__description {
                            text-transform: uppercase;
                            font-size: 24px;
                        }
                    }
                }

                .huddleboard-overall {
                    box-sizing: border-box;
                    width: 100%;
                    height: 135px;
                    padding: 8px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    background-color: var(--status-color);
                    border-radius: 12px;

                    &__value, &__description {
                        color: white;
                    }

                    &__value {
                        line-height: 72px;
                        font-size: 70px;
                    }

                    &__description {
                        line-height: 28px;
                        font-size: 24px;
                    }
                }
            }

            .huddleboard-divider {
                margin: {
                    top: 24px;
                    bottom: 24px;
                };
            }

            hr {
                border: none;
                height: 2px;
                width: 100%;
            }
        }

        .huddleboard-container.fullScreen {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            gap: 32px;

            .huddleboard-item-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 24px;

                .huddleboard-item-title {
                    font-size: 58px;
                }
            }
        }

        .huddleboard-item.fullScreen {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 42px;

            display: flex;
            justify-content: center;
            gap: 64px;

            border-radius: 32px;
            background-color: white;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15), 0 -1px 6px rgba(0, 0, 0, 0.15);

            > .primary-section {
                width: 50%;

                display: flex;
                flex-direction: column;
                align-items: center;

                .status {
                    position: relative;
                    width: calc(100% - 150px);
                    min-height: 70px;
                    box-sizing: border-box;
                    padding: 8px 4px;

                    align-self: end;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 8px;

                    background-color: var(--speech-bubble-bg-color);
                    border-radius: 12px;

                    text-align: center;
                    text-transform: uppercase;
                    font-size: 30px;

                    &__word {
                        color: var(--status-color);
                    }

                    &__emoji {
                        position: absolute;
                        top: 10px;
                        left: -160px;
                        width: 130px;

                        transform: scaleX(-1);
                    }

                    &:after {
                        content: '';
                        margin-top: 15px;
                        position: absolute;
                        top: 0;
                        left: -18px;
                        transform: scaleX(-1);

                        border-style: solid;
                        border-width: 30px 0 0 20px;
                        border-color: transparent transparent transparent var(--speech-bubble-bg-color);
                    }
                }

                .kpi {
                    flex: 1;
                    width: 100%;
                    box-sizing: border-box;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;

                    &__value {
                        line-height: 240px;
                        font-size: 225px;
                    }

                    &__description {
                        word-break: break-word;
                        text-transform: uppercase;
                        text-align: center;
                        font-size: 52px;
                    }

                    &__date {
                        text-transform: uppercase;
                        font-size: 42px;
                    }
                }
            }

            > .secondary-section {
                width: 50%;
                overflow: hidden;

                display: flex;
                gap: 16px;
                flex-direction: column;

                .percentage {
                    width: 100%;
                    flex: 3;
                    box-sizing: border-box;
                    padding: 0 64px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    background-color: var(--status-color);
                    color: white;
                    border-radius: 12px;

                    font-size: 52px;
                    text-transform: uppercase;

                    &__value {
                        line-height: 110px;
                        font-size: 110px;
                    }
                }

                .goal {
                    width: 100%;
                    flex: 4;
                    box-sizing: border-box;
                    padding: 0 64px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 16px;

                    border-radius: 12px;

                    font-size: 52px;
                    text-transform: uppercase;
                    text-align: left;

                    &__value {
                        &:only-child {
                            align-self: center;
                        }
                    }

                    &__description {
                        word-break: break-word;
                        font-size: 50px;
                    }
                }
            }
        }
    }

}
