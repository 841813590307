.LeaderboardTable {
    table {
        overflow: scroll;
        width: 100%;
        height: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        tbody, thead {
            tr {
                th, td {
                    text-align: left;
                    font-size: 32px;
                    height: 70px;
                    background-color: transparent;

                    &.right {
                        text-align: right;
                    }
                }

                th {
                    padding: 2px 16px;
                }

                td {
                    padding: 10px 16px;
                    border-bottom: 1px solid #6E6E6E;
                    font-weight: 600;
                }
            }
        }

        .employee-column {
            white-space: nowrap;
            width: 350px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}