.BackOfHouse {
    &.boh_type_shift_log {
        padding: 5vh 10vw;
        padding-bottom: calc(5vh + 100px)
    }

    .ShiftTask {
        .title {
            margin: 32px 0 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: var(--title-font-size-large);
            text-transform: uppercase;
            min-height: 1em;
            line-height: 1;

            .text {
                width: 90%;
            }

            .logo {
                height: 1.5em;
            }
        }

        .shift-logs-list-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 720px;

            .shift-logs-list {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                width: 100%;
                margin-top: 48px;

                .list-item {
                    height: 70px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 13px;

                    background-color: #FFFFFF;
                    text-transform: capitalize;
                    font-weight: 400;

                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);

                    .item-time,
                    .item-title,
                    .item-status {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        font-size: 32px;
                        margin: 0;
                        text-transform: capitalize;
                    }

                    .item-time {
                        width: 10%;
                        justify-content: flex-end;
                        padding-right: 20px;

                        text-transform: uppercase;
                        font-weight: 600;
                    }

                    .item-title {
                        width: 70%;
                        padding-left: 20px;
                        box-sizing: border-box;
                    }

                    .item-status {
                        width: 20%;
                        justify-content: center;

                        text-transform: uppercase;
                        color: #FFFFFF;
                        font-weight: 600;

                        &.complete {
                            background-color: #00AE4C
                        }

                        &.not-complete {
                            background-color: #C2344A
                        }
                    }
                }
            }
        }
    }
}
