@import '_colors.scss';

#header {
    grid-area: header;
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    display: flex;
    border-bottom: 1px solid #D3DAE6;
    height: 49px;
    z-index: 1035;

    .open_mobile_menu {
        width: 48px;
        line-height: 48px;
        text-align: center;
        display: none;
        position:relative;
        &:after {
            width: 1px;
            left: auto;
            right: 0;
            position: absolute;
            content: '';
            top: 16px;
            bottom: 0;
            background: #D3DAE6;
        }
        &:hover, &:focus {
            svg {
                color: $DarkSlateBlue;
            }
        }
    }

    .logo {
        width: 49px;
        line-height: 48px;
        text-align: center;
        position: relative;
        img {
            width: 35px;
        }
        &:after {
            width: 1px;
            left: auto;
            right: 0;
            position: absolute;
            content: '';
            top: 16px;
            bottom: 0;
            background: #D3DAE6;
        }
    }

    .search_bar {
        margin-left: 8px;
        margin-top: 5px;

        width: 100%;
        max-width: 470px;
    }

    .right-side {
        margin-left: auto;
        line-height: 48px;
        position:relative;
    }

    .user {
        text-align: center;
        line-height: 48px;
        width: 48px;
        position:relative;
        img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
        }
        &:before {
            width: 1px;
            left: 0;
            right: auto;
            position: absolute;
            content: '';
            top: 16px;
            bottom: 0;
            background: #D3DAE6;
        }
        .user-loading, .user-error {
            position: absolute;
        }
        .user-loading {
            top: 9px;
            left: 9px;
        }
        .user-error {
            line-height: 18px;
            top: 10px;
            left: 5px;
            border-radius: 50%;
            background: white;
            width: 18px;
            height: 18px;
        }
    }

    @media only screen and (max-width: 574px) {
        .open_mobile_menu {
            display: block;
        }

        .search_bar {
            width: calc(100% - 140px);
        }
        .open_mobile_menu, .logo, .user {
            &:before, &:after {
                display: none;
            }
        }
    }
}

.notifications_menu > .popover  {
    max-width: 400px !important;
}