.BackOfHouse {
    .avg-guest-spend-container {
        width: 1168px;
        height: 100%;

        box-sizing: border-box;
        padding-right: 1%;

        .width-container {
            width: 80%;

            .header {

                .title {
                    font-size: 62px;
                    text-align: left;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                    min-height: 1em;
                    line-height: 1;
                }

                .description {
                    font-size: 32px;
                    text-align: left;
                    margin: 0;
                    color: inherit;
                }
            }

            .avg-guest-spend-list {
                display: flex;
                flex-direction: column;

                margin-top: 36px;

                .list-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    font-weight: 400;
                    height: 28px;

                    .header-employee {
                        width: 13%;
                        text-align: end;
                    }

                    .header-guests {
                        width: 54%;
                        text-align: end;
                    }

                    .header-amount {
                        width: 27%;
                        text-align: end;
                    }

                    .employees {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0;
                        font-weight: 600;
                    }

                    &.list-header {
                        padding: 21px;
                        color: #fafafa;
                        display: flex;
                        align-items: center;

                        p {
                            font-size: 32px;
                        }
                    }


                    &.list-item {
                        height: 65px;
                        padding: 14px 21px;
                        border-bottom: 1px solid #6E6E6E;
                        font-weight: 600;

                        .name {
                            font-size: 32px;
                            width: 435px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-transform: capitalize;
                        }

                        .new-item {
                            font-size: 32px;
                            z-index: 1;
                        }

                        .guest_total {
                            width: 325px;
                            display: flex;
                            justify-content: flex-end;
                            font-size: 32px;
                            text-transform: capitalize;

                        }

                        .amount {
                            width: 325px;
                            display: flex;
                            justify-content: flex-end;
                            font-size: 32px;
                            text-transform: capitalize;


                        }
                    }
                }

                .results-description {
                    font-size: 24px;
                    text-align: right;
                    margin: 0;
                    color: inherit;
                    font-style: italic;
                    margin-top: 14px;
                    opacity: 0.7;
                }
            }
        }

    }
}
