.BackOfHouse {
    .loyalty-gauge {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .header {
            position: relative;
            width: 100%;
            margin-top: 30px;

            .logo {
                position: absolute;
                top: 0;
                right: 20px;
                width: 140px;
            }

            .text {
                margin-left: 8%;

                .title {
                    text-transform: uppercase;
                    text-align: left;
                    margin: 0;
                }

                .updated-time {
                    margin: 0;
                    font-size: 35px;
                }
            }
        }
    }
}